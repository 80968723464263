@import "@styles/variables.scss";

.type5 {
  font-size: 20px;
  font-weight: 400;
  border-radius: 40px;
  padding: 0.7rem 1.2rem;
  width: auto;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  gap: 13px;

  &:hover {
    i {
      transform: translateX(10px);
    }
  }

  img {
    width: 10px;
    height: 15px;
    object-fit: contain;
  }

  i {
    font-size: 20px;
    transition: 0.3s ease-in-out;
  }

  @media (width <= $mobile) {
    font-size: 16px;
  }
}
